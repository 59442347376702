<template>
  <div class="columns has-background-white is-centered">
    <div 
      v-if="!showSuccess"
      class="column is-half"
    >    
      <h1 class="title is-size-5">Password reset</h1>
      <form @submit.prevent="onSubmit">
        <BaseInput
          label="Email" 
          type="email"
          v-model.trim="$v.user.email.$model"
          :validation="$v.user.email"
        />
        <BaseSubmit
          label="Send password reset link"
          :error="error"
          :disabled="$v.$invalid"
          :submitting="submitting"
        />
      </form>
      <p class="subtitle is-size-6" style="margin-top:10px;">
        Don't have an account? <a @click="signup">Sign up</a>
      </p>
    </div>
    <div 
      v-else
      class="column is-half"
    >  
      <p>Thank you. We'll email you a password reset link shortly if you have an account associated with the email.  Please check your email in a few moments</p>
    </div>
  </div>
</template>

<script>
import api from "@/service/api.js";

import { email, required } from 'vuelidate/lib/validators';
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      submitting: false,
      showSuccess: false,
      error: null,
      user: {
        email: '',
      }
    }
  },
  validations: {
     user: {
      email: { required, email},
    }
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    signup() {
      const path = (window.NativeScript) ? "/signup?t=member" : "/get-started"
      this.go(path)
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const params = {
        email: this.user.email,
      }
      api.forgotPassword(params)
      .then(() => {
        this.submitting = false
        this.showSuccess = true
      })
      .catch(error => {
        if (error.response && error.response.status && error.response.status == 404) {
          this.error = error.response.data.message
        } else {
          this.error = error.message
        }
        this.submitting = false
      })
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/")
    } 
  },
}
</script>
